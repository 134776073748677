import AuthenticationProvider, { AuthenticationConfig, AutoLogin } from '@iad-os/react-ghost-auth';
//import usePWA from './hooks/usePWA';
import { AuthorizationProps } from '@iad-os/react-ghost-auth/dist/Authentication';
import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import './App.scss';
import ErrorManagement from './app/errors/ErrorManagement';
import InstallPWA from './components/core/InstallPWA';
import ProtectedRoutes from './components/routes/ProtectedRoutes';
import PublicRoutes from './components/routes/PublicRoutes';
import LoaderFullScreen from './components/shared/loader/loaderFullscreen/LoaderFullScreen';
import ModalApp from './components/shared/redux/modal/ModalApp';
import useIsIOS from './hooks/useIsIOS';
import useWellKnown from './hooks/useWellKnown';
import BASE_PATHS from './routes/basePaths';
import { useRoute } from './utils/routes/useRoute';
import CookieConsent from 'react-cookie-consent';

function App() {
    const { wellKnown, loading } = useWellKnown();

    const { prompt } = useIsIOS();

    const { changeRoute } = useRoute();
    type consensType = { consens: string; date?: Date };
    //const { installed } = usePWA();
    const authConfig = useMemo<AuthenticationConfig | undefined>(
        () =>
            wellKnown?.metadata
                ? {
                      providers: [
                          {
                              ...wellKnown.metadata.issuer,
                              issuer: wellKnown.metadata.issuer.name || 'default',
                              name: wellKnown.metadata.issuer.name || 'default',
                          },
                      ],
                  }
                : undefined,
        [wellKnown]
    );

    const handleOnRoute: AuthorizationProps['onRoute'] = route => {
        const url = new URL(route);
        changeRoute(url.pathname);
    };

    const [consentFileName] = useState<string>(`userConsentIadEngage${window.location.host}`);

    const [consent, setConsent] = useState(false);
    useEffect(() => {
        const consentFileName: string = `userConsentIadEngage${window.location.host}`;

        const userConsentString = localStorage.getItem(consentFileName);
        const userConsent: consensType = userConsentString ? JSON.parse(userConsentString) : { consens: 'false' };
        setConsent(userConsent.consens === 'true');
    }, []);

    const handleAccept = () => {
        const consen: consensType = { consens: 'true', date: new Date() };
        localStorage.setItem(consentFileName, JSON.stringify(consen));
        setConsent(true);
    };

    if (!consent) {
        const style: React.CSSProperties = {
            position: 'fixed',
            top: 5,
            left: 0,
            width: '100%',
            height: '90%',
            backgroundImage: `url('${wellKnown?.metadata?.consensImage ?? wellKnown?.metadata?.logo?.url}')`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            zIndex: -1,
        };

        return (
            <div style={style}>
                <CookieConsent
                    onAccept={handleAccept}
                    location="bottom"
                    buttonText="Accetto"
                    cookieName={consentFileName}
                    style={{ background: '#2B373B' }}
                    buttonStyle={{ color: '#4e503b', fontSize: '13px' }}
                    expires={150}
                >
                    Questo sito utilizza i cookie per migliorare l'esperienza utente.{' '}
                    <span style={{ fontSize: '10px' }}>I tuoi cookie saranno conservati per 150 giorni.</span>
                </CookieConsent>
            </div>
        );
    }

    return (
        <>
            {!loading && authConfig && (
                <AuthenticationProvider
                    overrideRedirectUri={lo => lo.href}
                    config={authConfig}
                    onRoute={handleOnRoute}
                    saveOnLocalStorage
                    enableLog
                >
                    <Override />
                    {prompt && <InstallPWA />}
                    <LoaderFullScreen />
                    <ErrorManagement />
                    <ModalApp />
                    <div about="switch">
                        <Switch>
                            <Route path={BASE_PATHS.PUBLIC}>
                                <PublicRoutes />
                            </Route>
                            <Route path={BASE_PATHS.PROTECTED}>
                                <ProtectedRoutes />
                            </Route>
                            <Redirect exact from={'/'} to={BASE_PATHS.PROTECTED} />
                        </Switch>
                    </div>
                    <AutoLogin />
                </AuthenticationProvider>
            )}
        </>
    );
}

const Override = () => {
    const { wellKnown } = useWellKnown();

    useLayoutEffect(() => {
        if (wellKnown && wellKnown.metadata) {
            if (wellKnown.metadata.icon) {
                let link: any = document.querySelector("link[rel~='icon']");
                if (!link) {
                    link = document.createElement('link');
                    link.rel = 'icon';
                    document.head.appendChild(link);
                }
                link.href = wellKnown.metadata.icon;
            }

            if (wellKnown.metadata.title) {
                document.title = wellKnown.metadata.title;
            }
        }
    }, [wellKnown]);
    return <></>;
};

export default App;
